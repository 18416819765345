<template>
  <div>
    <app-login-navigation v-if="!$store.state.user.isAuthenticated" />

    <v-container
      fluid
      class="mt-6"
      style="opacity: 0.9"
    >
      <basic-card
        title="$l.paths.apiInvestigation"
        class="mt-9"
      >
        <CheckList />
      </basic-card>
    </v-container>
  </div>
</template>

<script>
import CheckList from '@/components/api/CheckList.vue'

export default {
  name: 'ApiCheckListWrapper',
  components: {
    CheckList
  }
}
</script>
