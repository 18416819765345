<template>
  <ApiCheckListWrapper />
</template>

<script>
import ApiCheckListWrapper from '@/components/api/ApiCheckListWrapper'

export default {
  name: 'ApiCheckList',
  components: {
    ApiCheckListWrapper
  }
}
</script>
